.homePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header {
    margin-left: -70em;
    margin-top: 3em;
}

.SwaGrp {
    width: 200px;
    height: 100%;
    border-bottom: 1px solid black;
    padding: 2px 30px;
}

.businessGrp {
    display: flex;
    margin-top: -1em;
    align-items: center;
    justify-content: center;
    
}

.swacc {
    width: 200px;
    height: 200px;
}

.swaconst1 {
    border-right: 1px solid black;
    padding: 25px 25px;
    display: flex;
    flex-direction: column;
    
}

p {
    font-family: 'Montserrat';
}

.swaconst2 {
    border-right: 1px solid black;
    padding: 25px 55px;
    display: flex;
    flex-direction: column;
    
}

.swaconst3 {
   
    padding: 25px 55px;
    display: flex;
    flex-direction: column;
}

.socialIcons1 {
    display: flex;
    gap: 1em;
    margin-top: 4.5em;
}
.socialIcons2 {
    display: flex;
    gap: 1em;
    margin-top: 8.2em;
}.socialIcons3 {
    display: flex;
    gap: 1em;
    margin-top: 1.4em;
}
.socialLogo {
    width: 25px;
    height: 25px;
}

@media only screen and (max-width: 600px) {
    .homePage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .header {
        margin-left: -10em;
        margin-top: 1em;
    }
    
    .SwaGrp {
        width: 200px;
        height: 100%;
    }
    
    .businessGrp {
        display: flex;
        flex-direction: column;
        margin-top: -1em;
        
    }
    
    .swacc {
        width: 100px;
        height: 100px;
    }
    
    .swaconst1 {
        border-right: none;
        border-bottom: 1px solid black;
        padding: 25px 55px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    
    p {
        font-family: 'Montserrat';
        font-size: 0.7em;
        text-align: center;
    }
    
    .swaconst2 {
        border-right: none;
        border-bottom: 1px solid black;
        padding: 25px 55px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    
    .swaconst3 {
       
        padding: 25px 55px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .socialIcons1 {
        display: flex;
        gap: 1em;
        margin-top: 2em;
    }
    .socialIcons2 {
        display: flex;
        gap: 1em;
        margin-top: 2em;
    }.socialIcons3 {
        display: flex;
        gap: 1em;
        margin-top: 2em;
    }
    .socialLogo {
        width: 25px;
        height: 25px;
    }
    


}